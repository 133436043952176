export default ({ app }) => {
  app.head.script.push({
    hid: 'gtm-script',
    innerHTML: `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-PVG28J4');
    `,
    type: 'text/javascript'
  });

  app.head.__dangerouslyDisableSanitizersByTagID = {
    'gtm-script': ['innerHTML']
  };

  app.router.afterEach((to, from) => {
    if (process.client) {
      const noscript = document.createElement('noscript');
      noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PVG28J4"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
      document.body.appendChild(noscript);
    }
  });
};